import { Warning } from "@mui/icons-material";
import { Box } from "@mui/material";

const HazardIcon = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 60,
                height: 60,
                borderRadius: '50%',
                backgroundColor: 'red',
                color: 'white',
            }}
        >
            <Warning fontSize="large" /> {/* Use 'large' instead of pixel size */}
        </Box>
    );
}

export default HazardIcon;
