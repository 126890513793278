import { Button } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import WaveSurfer from "wavesurfer.js";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { FileDownloadOutlined, PauseCircleFilled } from '@mui/icons-material';

const WaveformPlayer = ({ audioUrl }) => {
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (waveformRef.current) {
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: "#D9D9D9", // Gray wave color for background
                progressColor: "#000000",
                height: 40,
                barWidth: 3,
                url: audioUrl ? audioUrl : "https://assets.mixkit.co/music/preview/mixkit-tech-house-vibes-130.mp3"
            });

            wavesurfer.current.on('play', () => setIsPlaying(true));
            wavesurfer.current.on('pause', () => setIsPlaying(false));
        }

        return () => {
            if (wavesurfer.current) {
                wavesurfer.current.destroy();
            }
        };
    }, [audioUrl]);

    const handlePlayPause = () => {
        if (wavesurfer.current) {
            wavesurfer.current.playPause();
        }
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(audioUrl ? audioUrl : "https://assets.mixkit.co/music/preview/mixkit-tech-house-vibes-130.mp3");
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'audio.mp3'; // Set a default file name
            link.click();
            window.URL.revokeObjectURL(url); // Clean up the URL object
        } catch (error) {
            console.error("Download failed", error);
        }
    };

    return (
        <div style={{ background: "rgba(177, 177, 190, 0.11)", position: "relative", display: "flex", alignItems: "center", padding: "5px", borderRadius: "8px", }}>
            <Button onClick={handlePlayPause}>
                {isPlaying ? <PauseCircleFilled sx={{ fontSize: 30, color: "#020429" }} /> : <PlayCircleIcon sx={{ fontSize: 30, color: "#020429" }} />}
            </Button>
            <div style={{ width: "100%" }} id="waveform" ref={waveformRef}></div>
            <Button onClick={handleDownload}>
                <FileDownloadOutlined sx={{ fontSize: 30, color: "#020429" }} />
            </Button>
        </div>
    );
};

export default WaveformPlayer;
