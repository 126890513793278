import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = () => {
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        width: "100%",
        height: "100dvh",
      }}
    >
      <CircularProgress sx={{ color: '#02042d' }} />
    </Box>
  );
};

export default Loader;
