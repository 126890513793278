import { ContentCopyOutlined } from '@mui/icons-material';
import React from 'react';
import { toast } from 'sonner';

const ClipboardBox = ({ id }) => {
    return (
        <div style={{
            position: "relative",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            border: "1px solid #02042D",
            borderRadius: "8px",
            padding: '0 .5rem',
            width: "fit-content",
            height: "1.5rem",
        }}>
            <p style={{ fontSize: "14px", color: "#02042D", lineHeight: "2rem" }}>
                {id && id.length > 8 ? id.slice(0, -8) : id}
            </p>
            <div style={{
                width: "40%",
                height: "1.4rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginRight: 4,
                background: "linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 1))",
                position: "absolute",
                right: 0,
                borderRadius: "8px"
            }}>
                <ContentCopyOutlined
                    sx={{
                        cursor: "pointer",
                        fontSize: "1rem",
                        color: "#02042D"
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(id);
                        toast.success("Copied to clipboard");
                    }}
                />
            </div>
        </div >
    );
};

export default ClipboardBox;
