import React, { useState, useEffect, useMemo, Suspense, useRef } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Collapse,
  Paper,
  Button,
} from '@mui/material';
import { ExpandLess, ExpandMore, Assistant, DashboardCustomize, CallOutlined, ListAltOutlined, PersonOutlined, SpaceDashboardOutlined, AssistantOutlined, WorkOutline, StorageOutlined, Description, SettingsOutlined, GroupOutlined, LogoutOutlined, KeyOutlined } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import '../styles/sidebarLayout.css';
import Loader from './Loader';
import Credit from './Credit';

const SidebarLayout = ({ children }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Manage sidebar visibility
  const [textVisible, setTextVisible] = useState(true); // Manage text visibility
  const [companyId, setCompanyId] = useState(""); // Manage company
  const location = useLocation(); // To get the current URL
  const [open, setOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("User"));
  const id = localStorage.getItem("Organization");
  const ref = useRef(null);

  const userRole = localStorage.getItem("Role");

  useEffect(() => {
    const checkSidebarState = () => {
      const storedSidebarState = localStorage.getItem('sidebarOpen');
      if (storedSidebarState !== null) {
        setSidebarOpen(JSON.parse(storedSidebarState));
        setTextVisible(JSON.parse(storedSidebarState));
      }
      setCompanyId(localStorage.getItem('Organization'));
    };

    // Initially check the sidebar state
    checkSidebarState();

    // check if the sidebarOpen value has changed in localStorage
    const intervalId = setInterval(() => {
      checkSidebarState();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const logo = useMemo(() => (
    <img src={textVisible ? "/white-logo.png" : "/favicon.png"} alt="logo" />
  ), [textVisible]);

  const handleDropdownClick = (index) => {
    setOpenDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  const sidebarItems = [
    { type: 'link', label: 'Overview', url: '/overview', icon: <SpaceDashboardOutlined /> },
    { type: 'link', label: 'Assistant', url: '/assistants', icon: <AssistantOutlined /> },
    { type: 'link', label: 'Phone number', url: '/phone-number', icon: <CallOutlined /> },
    { type: 'link', label: 'Logs', url: '/logs', icon: <ListAltOutlined /> },
    { type: 'link', label: 'Company', url: '/company', icon: <WorkOutline /> },
    {
      type: 'dropdown',
      label: 'Forms',
      icon: <StorageOutlined />,
      items: [
        { label: 'Transcriber', url: '/transcriber', icon: <Description /> },
        { label: 'Transcriber Provider', url: '/transcriber-provider', icon: <Description /> },
        { label: 'Transcriber Language', url: '/transcriber-language', icon: <Description /> },
        { label: 'Voice Provider', url: '/voice-provider', icon: <Description /> },
        { label: 'Voice', url: '/voice', icon: <Description /> },
        { label: 'Voice Lanuage', url: '/voice-language', icon: <Description /> },
        { label: 'LLM Provider', url: '/llm-provider', icon: <Description /> },
        { label: 'LLM', url: '/llm', icon: <Description /> },
        { label: 'Phone Provider', url: '/phone-provider', icon: <Description /> },
      ],
    }
  ];

  const adminItems = [
    { type: 'link', label: 'Overview', url: `/company/${companyId}/overview`, icon: <DashboardCustomize /> },
    { type: 'link', label: 'Assistant', url: `/company/${companyId}/assistants`, icon: <Assistant /> },
    { type: 'link', label: 'Phone number', url: `/company/${companyId}/phone-number`, icon: <CallOutlined /> },
    { type: 'link', label: 'Logs', url: `/company/${companyId}/logs`, icon: <ListAltOutlined /> },
  ];

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant="permanent"
          open={sidebarOpen}
          sx={{
            flexShrink: 0,
            width: textVisible ? 240 : 85, // Adjust width based on text visibility
            transition: 'width 0.3s', // Smooth transition for width change
            '& .MuiDrawer-paper': {
              width: textVisible ? 240 : 85, // Adjust paper width
              boxSizing: 'border-box',
            },
          }}
        >
          <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: "0 1rem", borderRadius: 0 }} className='color-prim-cetacean-blue-500' style={{ height: "110vh", overflowY: 'auto' }}
            onClick={(e) => {
              if (e && e.target instanceof Node) {
                if (ref.current && !ref.current.contains(e.target)) {
                  setOpen(false);
                }
              }
            }}
          >
            <Link to="/overview" style={{ flexShrink: 0 }}>
              <Box className="logo">
                {logo}
              </Box>
            </Link>
            <List>
              {(userRole === "Admin" || userRole === "User") ? adminItems.map((item, index) => (
                <React.Fragment key={index}>
                  {item.type === 'link' ? (
                    <ListItem
                      button
                      component={Link}
                      to={item.url}
                      className={location.pathname.includes(item.url) ? 'sideLinksActive' : 'sideLinks'}                  >
                      {item.icon}
                      {textVisible && <ListItemText className="sideLinkText" primary={item.label} />}
                    </ListItem>
                  ) : (
                    <React.Fragment>
                      <ListItem className='sideLinks' button onClick={() => handleDropdownClick(index)} >
                        {item.icon}
                        {textVisible && <ListItemText primary={item.label} />}
                        {openDropdown === index ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openDropdown === index} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.items.map((subItem, subIndex) => (
                            <ListItem
                              button
                              component={Link}
                              to={subItem.url}
                              key={subIndex}
                              className={location.pathname === subItem.url ? 'sideLinksActive' : 'sideLinks'}
                              sx={{
                                pl: 4,
                              }}
                            >
                              {subItem.icon}
                              {textVisible && <ListItemText className="sideLinkText" primary={subItem.label} />}
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )) : sidebarItems.map((item, index) => (
                <React.Fragment key={index}>
                  {item.type === 'link' ? (
                    <ListItem
                      button
                      component={Link}
                      to={item.url}
                      className={location.pathname.includes(item.url) ? 'sideLinksActive' : 'sideLinks'}                  >
                      {item.icon}
                      {textVisible && <ListItemText className="sideLinkText" primary={item.label} />}
                    </ListItem>
                  ) : (
                    <React.Fragment>
                      <ListItem className='sideLinks' button onClick={() => handleDropdownClick(index)}>
                        {item.icon}
                        {textVisible && <ListItemText style={{ marginLeft: '0.5rem' }} primary={item.label} />}
                        {openDropdown === index ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openDropdown === index} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.items.map((subItem, subIndex) => (
                            <ListItem
                              button
                              component={Link}
                              to={subItem.url}
                              key={subIndex}
                              className={location.pathname === subItem.url ? 'sideLinksActive' : 'sideLinks'}
                              sx={{
                                pl: 4,
                              }}
                            >
                              {subItem.icon}
                              {textVisible && <ListItemText className="sideLinkText" primary={subItem.label} />}
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </List>
            <Box sx={{ marginTop: "auto", marginBottom: '1rem' }}>
              {(userRole === "Admin" || userRole === "User") && <Credit sidebarHidden={!textVisible} />}
            </Box>
            <Button
              onClick={() => setOpen(prev => !prev)}
              sx={{
                backgroundColor: '#353657',
                marginBottom: '0.5rem',
                textAlign: 'center',
                padding: '0.5rem',
                color: '#fff',
                textDecoration: 'none',
                width: '100%',
                flexShrink: 0,
                minWidth: 0,
              }}
              variant="contained"
            >
              <PersonOutlined />
              {
                textVisible
                &&
                "Profile"
              }
            </Button>
            {open && (<div style={{ position: "absolute", width: textVisible ? "13rem" : "fit-content", bottom: "3.5rem", backgroundColor: "#FFF", zIndex: "100", borderRadius: "8px" }}>
              <div ref={ref} style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px 0px" }}>
                {textVisible &&
                  <>
                    <div style={{ width: "100%", padding: "0px 16px" }}>
                      <p style={{ fontSize: "1rem", fontWeight: "500" }}>{user?.Name?.length > 12 ? `${user?.Name.slice(0, 10)}...` : user?.Name}</p>
                      <p color="#8A8A8A">{`${user?.Email?.length > 12 ? `${user?.Email.slice(0, 18)}...` : user?.Email}`}</p>
                    </div>
                    <hr style={{ border: "1px solid lightgray" }} />
                  </>

                }

                <Link to="/profile" style={{ display: "flex", alignItems: "center", gap: "10px", color: "#02042D", textDecoration: "none", padding: "6px 16px" }}>
                  <SettingsOutlined /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && "Account"}</span>
                </Link>

                <Link to={(userRole === "Admin" || userRole === "User") ? `/company/${id}/member` : `/company/${id}?tab=members`} style={{ display: "flex", alignItems: "center", gap: "10px", color: "#02042D", textDecoration: "none", padding: "6px 16px" }}>
                  <GroupOutlined /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && "Members"}</span>
                </Link>

                <Link to="/#" style={{ display: "flex", alignItems: "center", gap: textVisible ? "10px" : 0, color: "#02042D", textDecoration: "none", padding: "6px 16px" }}>
                  <KeyOutlined /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && " API Keys"}</span>
                </Link>

                <hr style={{ border: "1px solid lightgray" }} />

                <button style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: textVisible ? "10px" : 0, color: "#02042D", outline: "none", border: "none", cursor: "pointer", padding: "6px 16px", background: "none" }}
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                  }}
                >
                  <LogoutOutlined /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && "Logout"}</span>
                </button>


              </div>
            </div>)
            }
          </Paper>
        </Drawer>
        <Box
          component="main"
          sx={{ height: "100dvh", flexGrow: 1, bgcolor: "#FAFAFA", overflowX: 'hidden' }}
          onClick={(e) => {
            if (e && e.target instanceof Node) {
              if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
              }
            }
          }}
        >
          <Suspense fallback={<Loader />}>
            {children}
          </Suspense>
        </Box>
      </Box>

    </>
  );
};

export default SidebarLayout;
