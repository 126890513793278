import { Avatar, Box, Button, Card, CircularProgress, TextField, Typography } from '@mui/material';
import api from "../../api";
import React, { useState } from 'react'
import { toast } from 'sonner';
import "../../styles/login.css"
import { useNavigate } from 'react-router-dom';

const CreateCompanyForm = () => {
    const [formValues, setFormValues] = useState({
        Name: "",
        TeamSize: "",
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem("token"))?.token;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { Name, TeamSize } = formValues;
        if (!Name || !TeamSize) {
            return toast.error("Please fill all the fields");
        }

        setLoading(false);
        try {
            const config = {
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${token}`,
                },
            };
            const response = await api.post("/users/add-organization", { Name, TeamSize },
                config,
            );

            toast.success(response.data.message);

            localStorage.setItem("Organization", response.data.organization._id);
            localStorage.setItem("Role", response.data.user.Role);

            setLoading(false);

            window.location.href = `/company/${response.data.organization._id}/overview`;
            return navigate(`/company/${response.data.organization._id}/overview`);
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(error);
            setLoading(false)
        }
    }

    const handleDemoCompany = async () => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${token}`,
                },
            };
            const response = await api.get("/demo-organization/create-new", config);
            toast.success(response?.data.message || "Organization created successfully");
            localStorage.setItem("Organization", response.data?.organization._id);
            localStorage.setItem("Role", response.data?.user.Role);
            setLoading(false);
            window.location.href = `/company/${response.data.organization._id}/overview`;
            return navigate(`/company/${response.data.organization._id}/overview`);
        } catch (error) {
            toast.error(error.response?.data.message || "Something went wrong, please try again");
            console.error(error);
            setLoading(false);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };


    return (
        <>
            <Box className="loginContainer">
                <Box className="leftBox">
                    <img src="logo.png" className="logo" alt="Logo" />
                    <img src="/images/login/leftOverlay.png" className="leftOverlay" alt="Overlay" />
                    <Box mt={4} className="formbox">
                        <Typography className="loginAccountText"> Create New Company</Typography>
                        <Typography fontSize={12} textAlign="center">
                            Welcome! Create and explore Liaplus
                        </Typography>
                        <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "1.5rem", marginTop: "1rem" }}>
                            <TextField
                                label="Name"
                                placeholder="e.g. Jonathan"
                                name="Name"
                                value={formValues.Name}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                size="small"
                            />

                            <TextField
                                label="Team Size"
                                placeholder="e.g. 10"
                                name="TeamSize"
                                value={formValues.TeamSize}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                size="small"
                            />

                            <Button
                                variant="contained"
                                type="submit"
                                size="small"
                                sx={{
                                    bgcolor: "#02042D",
                                    padding: "0.5rem 0",
                                    fontWeight: "500",
                                    color: "#FFF",
                                    textTransform: "none",
                                    ":hover": {
                                        bgcolor: "#353657",
                                    },
                                }}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={25} sx={{ color: "#02042D" }} /> : "Create New Company"}
                            </Button>
                        </form>

                        <div style={{ marginTop: "1rem", display: "flex", alignContent: "center", gap: ".5rem" }}>
                            <p style={{ color: "gray", textAlign: "center" }}>
                                Or, would you like to explore using a demo account?
                            </p>

                            <button style={{ all: "unset", color: "#02042D", cursor: "pointer" }} onClick={handleDemoCompany}>Skip.</button>
                        </div>
                    </Box>
                </Box>

                {/* Right Box - Testimonial Section */}
                <Box className="rightBox">
                    <Card className="rightCard">
                        <Avatar
                            alt="Vandana"
                            src="/images/login/vandana.png"
                            sx={{ width: 50, height: 50 }}
                        />
                        <Typography className="cardText" mt={2}>
                            “LiaPlus AI has made my work much easier and more efficient. It's like having a personalized writing coach that saves time and boosts confidence.”
                        </Typography>
                        <Typography className="cardText" mt={2}>
                            Vandana Khurana
                        </Typography>
                        <Typography className="cardText">
                            BCO, India
                        </Typography>
                    </Card>
                </Box>
            </Box>
        </>
    )
}

export default CreateCompanyForm;