import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import { Box, Button, Checkbox, Chip, Modal, TextField, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PhoneIcon from '@mui/icons-material/Phone';
import api from "../../api";
import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import Inbound from '../../components/Inbound';
import Outbound from '../../components/Outbound';
import OutboundCall from "../Outbound"
import { Close, Edit } from '@mui/icons-material';
import ClipboardBox from '../../components/ClipboardBox';
import Loader from '../../components/Loader';

const PhoneNumberDetailAdmin = () => {
    const token = JSON.parse(localStorage.getItem("token"))?.token;
    const [data, setData] = useState({});
    const [activeForm, setActiveForm] = useState("inbound");
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [assistants, setAssistants] = useState([]);
    const { id } = useParams();

    const [formValues, setFormValues] = useState({
        PhoneNumber: '',
        ApiCredentials: '',
        Provider: '',
        Label: '',
    });

    const fetchAllAssistants = async () => {
        try {
            const response = await api.post(`/organizations/assistants-by-company`, {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                }
            );
            setAssistants(response.data.data);
        } catch (error) {
            console.error("Error fetching assistants:", error);
        }
    };

    const fetchPhoneById = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/phones/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setData(response.data);
            setFormValues({
                PhoneNumber: response.data.PhoneNumber,
                ApiCredentials: response.data.ApiCredentials,
                Provider: response.data.Provider._id,
                Label: response.data.Label
            });

            setLoading(false);

        } catch (error) {
            console.error("Error fetching phone:", error);
        }
    };

    const updatePhone = (activeForm) => {
        fetchPhoneById()
        setActiveForm(activeForm)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const { ApiCredentials, Label, PhoneNumber, Provider } = formValues;
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            await api.put(`/phones/${id}`, {
                ApiCredentials, Label, PhoneNumber, Provider
            }, config);

            setEditModalOpen(false);
            toast.success("Phone number updated successfully");

            fetchPhoneById();

        } catch (error) {
            console.error("Error updating phone:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token) {
            fetchPhoneById();
            fetchAllAssistants();
        }
    }, [token]);

    if (loading) {
        return <Loader />
    }

    return (
        <Box height={"100vh"}>
            <>
                <Navbar title={`Phone Number / ${data.PhoneNumber}`} />
                <Box sx={{ padding: "1rem" }}>
                    <Box sx={{ display: 'flex', alignItems: "flex-start", justifyContent: "space-between", }}>
                        <Box style={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                            <Box sx={{ display: "grid", placeItems: "center", padding: ".8rem", bgcolor: "#02042D", borderRadius: "50%" }}>
                                <PhoneIcon sx={{ fontSize: 24, color: "white" }} />
                            </Box>

                            <Box>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="subtitle2" component="h4">
                                        {data?.PhoneNumber} <Edit sx={{ cursor: "pointer", color: "#02042D", fontSize: "1.5rem", ml: ".5rem" }} onClick={() => setEditModalOpen(true)} />
                                    </Typography>
                                </div>

                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Chip
                                        label={data?.Label}
                                        variant="outlined"
                                        size="small"
                                        sx={{ borderRadius: "8px", borderColor: "#02042D", color: "#02042D", fontSize: "12px", padding: "12px 5px" }}
                                    />
                                    <ClipboardBox id={data?._id} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(450px, 1fr))", gap: "2rem", margin: "1.5rem", flexWrap: "wrap" }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1.5rem', borderRadius: "1rem", bgcolor: "#F5F5F5", height: "fit-content" }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: '1rem',
                                    borderRadius: 3,
                                    padding: ".5rem",
                                    color: activeForm === "inbound" ? "#FFF" : "#000",
                                    bgcolor: activeForm === "inbound" ? "#02042d" : 'none',
                                    cursor: 'pointer',
                                    border: "2px solid #A9A9A9"
                                }}
                                onClick={() => setActiveForm("inbound")}
                            >
                                <ArrowBackIcon sx={{ fontSize: 40 }} />
                                <Box>
                                    <Typography variant="subtitle1" style={{ cursor: 'pointer' }}>
                                        Inbound
                                    </Typography>
                                    <p>
                                        Assistant to handle incoming calls automatically.
                                    </p>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    borderRadius: 3,
                                    padding: ".5rem",
                                    color: activeForm === "outbound" ? "#FFF" : "#000",
                                    bgcolor: activeForm === "outbound" ? "#02042d" : 'none',
                                    cursor: 'pointer',
                                    border: "2px solid #A9A9A9"
                                }}
                                onClick={() => setActiveForm("outbound")}
                            >
                                <ArrowForwardIcon sx={{ fontSize: 40 }} />
                                <Box>
                                    <Typography variant="subtitle1">
                                        Outbound
                                    </Typography>
                                    <p>
                                        Assistant to reach out to users.
                                    </p>
                                </Box>
                            </Box>
                        </Box>

                        {activeForm === "inbound" ?
                            <Inbound assistants={assistants} data={data} updatePhone={updatePhone} />
                            :
                            <Box>
                                <Outbound assistants={assistants} data={data} updatePhone={updatePhone} />
                                {
                                    !!data.OutboundAssistantId ? <OutboundCall assistantId={data.OutboundAssistantId} /> : <Typography>Please Select the assistant so you can initiate calls.</Typography>
                                }
                            </Box>
                        }
                    </Box>
                </Box>

                <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
                    <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>

                        <Box sx={{ width: "30rem", bgcolor: "#FFF", padding: "2rem", borderRadius: "18.8px", display: "flex", flexDirection: "column", gap: "15.67px" }}>

                            <form onSubmit={handleUpdate} style={{ width: "100%", display: "flex", flexDirection: "column", gap: "18.8px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h5>Update Phone Number</h5>
                                    <Close onClick={() => setEditModalOpen(false)} />
                                </div>

                                <div style={{ display: "flex", flexDirection: "column", gap: "18.8px" }}>
                                    <TextField
                                        label="Phone Number"
                                        name="PhoneNumber"
                                        value={formValues.PhoneNumber}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                    />

                                    <TextField
                                        label="Label"
                                        name="Label"
                                        value={formValues.Label}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                    />

                                    <TextField
                                        label="API Credentials"
                                        name="ApiCredentials"
                                        value={formValues.ApiCredentials}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                        size="small"
                                        multiline
                                        rows={5}
                                    />
                                </div>

                                <Box display="flex" alignItems="center">
                                    <Checkbox
                                        id="consent-checkbox"
                                        checked={consentChecked}
                                        onChange={(e) => setConsentChecked(e.target.checked)}
                                    />
                                    <label htmlFor="consent-checkbox" style={{ fontSize: "12.537px" }} fontWeight="500">
                                        I agree to update this Phone Number. By updating this the Number will lose or gain access.
                                    </label>
                                </Box>

                                <div style={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center" }}>

                                    <Button type="submit" variant="contained" sx={{ textWrap: "nowrap", fontSize: "12.537px", bgcolor: "#02042D", textTransform: "none", padding: "8px 16px" }} disabled={!consentChecked}>
                                        Save & Updated
                                    </Button>

                                    <Button onClick={() => setEditModalOpen(false)} variant="outlined" sx={{ fontSize: "12.537px", color: "#02042D", borderColor: "gray", padding: "8px 16px" }}>
                                        Cancel
                                    </Button>
                                </div>

                            </form>

                        </Box>
                    </Box>
                </Modal>
            </>
        </Box>
    )
}

export default PhoneNumberDetailAdmin