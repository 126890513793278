import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from "sonner";
import api from "../api";

const Inbound = ({ assistants, data, updatePhone }) => {
    const [inbound, setInbound] = useState("");
    const [fallback, setFallback] = useState("");

    const [phoneNumberData, setPhoneNumberData] = useState({
        countryCode: "",
        countryName: "",
        phoneNumber: ""
    });
    const [error, setError] = useState(false);

    const handlePhoneNumberChange = (data) => {
        setPhoneNumberData(data);
    };

    const handleAssistantChange = (event) => {
        setInbound(event.target.value);
    };

    const updateInboundSettings = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"))?.token;
            if (!token) {
                throw new Error("Token not found");
            }

            const response = await api.put(`/phones/${data?._id}`, {
                InboundAssistantId: inbound,
                Fallback: fallback,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            toast.success("Inbound assistant updated successfully");
            updatePhone("inbound")
        } catch (error) {
            console.error("Error updating inbound settings:", error);
        }
    };

    useEffect(() => {
        if (data?.InboundAssistantId) {
            setInbound(data.InboundAssistantId);
        }
        if (data?.Fallback) {
            setFallback(data.Fallback);
        }
    }, [data]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem', borderRadius: "5px", bgcolor: "rgba(177, 177, 190, 0.11)", border: "1px solid #A9A9A9" }}>
            <Typography variant="subtitle2" component="h4">
                Inbound Settings
            </Typography>

            <Box>
                <InputLabel id="phone-label">Inbound Phone Number</InputLabel>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data?.PhoneNumber}
                    id="phone-label"
                    placeholder="1234567890"
                    disabled
                    sx={{ bgcolor: "#FFF" }}
                />
            </Box>

            <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="assistant-label">Assistant</InputLabel>
                <Select
                    labelId="assistant-label"
                    id="assistant"
                    value={inbound}
                    onChange={handleAssistantChange}
                    label="Assistant"
                    sx={{ bgcolor: "#FFF" }}
                >
                    {assistants?.length > 0 ? (
                        assistants.map((assistant) => (
                            <MenuItem key={assistant._id} value={assistant._id}>
                                {assistant.Name}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="">
                            No assistants available
                        </MenuItem>
                    )}
                </Select>
            </FormControl>

            <Box>
                <InputLabel id="phone-label">Fallback Number</InputLabel>
                <TextField
                    variant="outlined"
                    onChange={(e) => setFallback(e.target.value)}
                    size="small"
                    fullWidth
                    id="phone-label"
                    placeholder="Ex: 1234567890"
                    sx={{ bgcolor: "#FFF" }}
                />
            </Box>

            <Button variant="contained" sx={{ width: '5rem', bgcolor: '#020429' }} onClick={updateInboundSettings}>
                Save
            </Button>
        </Box>
    );
};

export default Inbound;
