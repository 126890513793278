import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import api from "../api";
import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';

const Outbound = ({ assistants, data, updatePhone }) => {
    const [outbound, setOutbound] = useState('');

    const handleAssistantChange = (event) => {
        setOutbound(event.target.value);
    };

    const updateOutboundSettings = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"))?.token;
            if (!token) {
                throw new Error("Token not found");
            }

            const response = await api.put(`/phones/${data?._id}`, {
                OutboundAssistantId: outbound,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            toast.success("Outbound assistant updated successfully");
            updatePhone("outbound")

        } catch (error) {
            console.error("Error updating outbound settings:", error);
        }
    };

    useEffect(() => {
        if (data) {
            setOutbound(data?.OutboundAssistantId);
        }
    }, [data]);

    return (
        <>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem', borderRadius: "5px", bgcolor: "rgba(177, 177, 190, 0.11)", border: "1px solid #A9A9A9" }}>
                <Typography variant="subtitle2" component="h4">
                    Outbound Settings
                </Typography>

                <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel id="assistant-label">Assistant</InputLabel>
                    <Select
                        labelId="assistant-label"
                        id="assistant"
                        value={outbound}
                        onChange={handleAssistantChange}
                        label="Assistant"
                        sx={{ bgcolor: "#FFF" }}
                    >
                        {assistants?.length > 0 && (
                            assistants.map((assistant) => (
                                <MenuItem key={assistant._id} value={assistant._id}>
                                    {assistant.Name}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>

                <Button variant="contained" sx={{ width: '5rem', bgcolor: '#020429' }} onClick={updateOutboundSettings}>
                    Save
                </Button>
            </Box>
        </>
    );
};

export default Outbound;
