import React, { Suspense, useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SidebarLayout from "./components/SidebarLayout";
import "./App.css";
import { AdminRoutes } from "./admin.route";
import { SuperAdminRoutes } from "./superadmin.route";
import VerifyEmail from "./pages/Verify";
import CreateCompanyForm from "./pages/admin/CreateCompanyForm";
import Loader from "./components/Loader";
import { toast, Toaster } from 'sonner';

const Login = React.lazy(() => import("./pages/Login"));
const Registration = React.lazy(() => import("./pages/Registration"));
const ForgetPassword = React.lazy(() => import("./pages/ForgetPassword"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));

function App() {
  const [token, setToken] = useState(() => JSON.parse(localStorage.getItem("token") || '{}')?.token);
  const [userRole, setUserRole] = useState(() => localStorage.getItem("Role"));
  const [organizationId, setOrganizationId] = useState(() => localStorage.getItem("Organization"));

  const refreshLocalStorageData = () => {
    setToken(JSON.parse(localStorage.getItem("token") || '{}')?.token);
    setUserRole(localStorage.getItem("Role"));
    setOrganizationId(localStorage.getItem("Organization"));
  };

  useEffect(() => {
    refreshLocalStorageData();
    const handleStorageChange = () => {
      refreshLocalStorageData();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const isSession = localStorage.getItem('IsSession');
    if (isSession) {
      const sessionSessionId = sessionStorage.getItem('SessionID');
      const localSessionId = localStorage.getItem('SessionID');
      if (sessionSessionId !== localSessionId) {
        localStorage.clear();
        sessionStorage.clear();
        toast.error("Session is Expired");
        window.location.reload();
      }
    }
  }, []);

  return (
    <main className="App">
      <Toaster
        richColors
        position="top-right"
        closeButton
        duration="1500"
      />
      <CssBaseline />
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            {!token ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/forgot-password" element={<ForgetPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/verifyemail" element={<VerifyEmail />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            ) : userRole === "User" && !organizationId ? (
              <Route path="*" element={<CreateCompanyForm />} />
            ) : (
              <Route
                path="*"
                element={
                  <SidebarLayout>
                    {userRole === "Admin" && <AdminRoutes />}
                    {userRole === "User" && <AdminRoutes />} {/* Adjusted for clarity */}
                    {userRole === "SuperAdmin" && <SuperAdminRoutes />}
                  </SidebarLayout>
                }
              />
            )}
          </Routes>
        </Suspense>
      </Router>
    </main>
  );
}

export default App;