import React from 'react';
import { Card, CardContent, Typography, Button, Chip, Box, Avatar } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { CallReceived } from '@mui/icons-material';


const CallCard = ({ data, url }) => {
    const navigate = useNavigate();

    return (
        <Card sx={{ borderRadius: "1rem", border: "1px solid #A9A9A9", boxShadow: 0, padding: "1rem 1.2rem", bgcolor: "#FFF" }}>
            <CardContent style={{ height: "150px", display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "0" }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: ".5rem" }}>

                    <Box sx={{ display: "grid", placeItems: "center", padding: ".6rem", bgcolor: "#02042D", borderRadius: "50%" }}>
                        <PhoneIcon sx={{ fontSize: "1.5rem", color: "white" }} />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                        <p style={{ fontSize: "1.2rem", fontWeight: "600", color: "#02042D", lineHeight: "normal" }}>
                            {data.Label || "No Label"}
                        </p>

                        <Typography variant="caption" component="span">
                            {data.PhoneNumber}
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, maxWidth: '100%' }}>
                            <Chip
                                avatar={
                                    <Avatar
                                        alt={data.OutboundAssistantId?.Name || "NA"}
                                        src={data.OutboundAssistantId?.Logo?.fileUrl}
                                    />
                                }
                                label={data.OutboundAssistantId?.Name.slice(0, 15) || "NA"}
                                deleteIcon={<CallMadeIcon sx={{ color: "#02042D" }} />}
                                onDelete={() => { }}
                                variant="outlined"
                                size="small"
                                sx={{ color: "#02042D", borderColor: "#02042D", overflow: "hidden" }}
                            />

                            <Chip
                                avatar={
                                    <Avatar
                                        alt={data.InboundAssistantId?.Name || "NA"}
                                        src={data.InboundAssistantId?.Logo?.fileUrl}
                                    />
                                }
                                label={data.InboundAssistantId?.Name.slice(0, 15) || "NA"}
                                size="small"
                                variant="outlined"
                                deleteIcon={<CallReceived color="#02042D" sx={{ color: "red" }} />}
                                onDelete={() => { }}
                                sx={{ color: "#02042D", borderColor: "#02042D", overflow: "hidden" }}
                            />
                        </Box>
                    </Box>

                </Box>

                <Button variant="contained" color="primary" style={{ width: "100%", backgroundColor: '#020429' }} key={data.id} onClick={() => navigate(url)}>
                    View Details
                </Button>

            </CardContent>
        </Card >
    );
};



export default CallCard;