import React, { useEffect, useRef, useState } from 'react'

const OtpBox = ({ length, handleSubmit }) => {
    const [otp, setOtp] = useState(new Array(length).fill(""));
    const inputRef = useRef([]);

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) return;

        const newVal = [...otp];
        newVal[index] = value.slice(-1);

        setOtp(newVal);

        if (value && index < length - 1) {
            for (let i = index + 1; i < length; i++) {
                if (newVal[i] === "") {
                    inputRef.current[i]?.focus();
                    break;
                }
            }
        }

        const otpValue = newVal.join("");
        handleSubmit(otpValue);
    }

    const handlePaste = (e, index) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').replace(/\D/g, '');

        if (!pastedData) return;

        const newOtp = [...otp];

        // Distribute pasted digits across input fields
        for (let i = 0; i < length && i < pastedData.length; i++) {
            newOtp[i] = pastedData[i];
        }

        setOtp(newOtp);

        // Focus on the next empty field or the last field
        const nextEmptyIndex = newOtp.findIndex((val, idx) => idx >= index && val === "");
        if (nextEmptyIndex !== -1) {
            inputRef.current[nextEmptyIndex]?.focus();
        } else {
            inputRef.current[length - 1]?.focus();
        }

        const otpValue = newOtp.join("");
        handleSubmit(otpValue);
    }



    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRef.current[index - 1]?.focus();
        } else if (e.key === "ArrowLeft" && index > 0) {
            inputRef.current[index - 1]?.focus();
        } else if (e.key === "ArrowRight" && index < length - 1) {
            inputRef.current[index + 1]?.focus();
        }
    }

    useEffect(() => {
        inputRef.current[0]?.focus();
    }, [])

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            {otp.map((value, index) => (
                <input
                    type="text"
                    key={index}
                    value={value}
                    inputMode="numeric"
                    ref={(input) => { inputRef.current[index] = input }}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={(e) => handlePaste(e, index)}
                    style={{ textAlign: "center", width: "40px", height: "40px", border: "1px solid #02042D", borderRadius: "8px", fontSize: "16px", fontWeight: "500", background: "rgba(177, 177, 190, 0.12)" }}
                    autoComplete="off"
                />
            ))}
        </div>
    )
}

export default OtpBox