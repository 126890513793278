import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Add } from '@mui/icons-material'

const NoDataFound = ({ title = "No Data Found", subtitle = "Please add some data", buttonLabel, buttonLink }) => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleButtonClick = () => {
        navigate(buttonLink); // Navigate to the specified link
    };

    return (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <img src={`${process.env.PUBLIC_URL}/blank.png`} alt="No Data Found" />
                <Typography variant="h6" sx={{ color: '#7c7c7c' }}>{title}</Typography> {/* Add margin-bottom */}
                <Typography variant="body1" sx={{ mb: 2, color: '#7c7c7c' }}>{subtitle}</Typography> {/* Add margin-bottom */}
                {
                    buttonLabel && buttonLink &&
                    <Button onClick={handleButtonClick} variant="contained" style={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize', backgroundColor: '#02042D', color: '#fff' }}>
                        <Add fontSize='25' fontWeight='bold' />
                        {buttonLabel}
                    </Button>
                }
            </Box>
        </div>
    );
};

export default NoDataFound;
