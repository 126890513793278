import { AttachMoney } from '@mui/icons-material';
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import api from '../api';

const Credit = ({ sidebarHidden = true }) => {
    const [credit, setCredit] = useState(0);
    const token = JSON.parse(localStorage.getItem("token")).token;
    const id = localStorage.getItem("Organization");

    const getCredit = async () => {
        try {
            const response = await api.post("/organizations/credits", {
                organizationId: id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            setCredit(response.data.data?.credits);
            localStorage.setItem("Credit", response.data.data?.credits || 0);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (token) {
            if (credit) {
                setInterval(getCredit, 100);
            } else {
                getCredit();
            }
        }
    }, [token]);

    if (sidebarHidden) {
        return (
            <Box style={{ backgroundColor: '#fff', padding: '1rem 0.2rem' }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: "column", justifyContent: 'center' }}>
                    <AttachMoney sx={{ color: "#02042d" }} />
                    <Typography variant="h6">{credit}</Typography>
                </Box>
            </Box>
        )
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem ", border: "1px solid #A9A9A9", padding: ".5rem .8rem", borderRadius: ".5rem" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Box style={{ display: "grid", placeItems: "center", width: "2rem", height: "2rem", backgroundColor: "#8b8c9e", borderRadius: "5px" }}>
                    <AttachMoney sx={{ color: "#FFF" }} />
                </Box>
                <Typography variant="subtitle2" sx={{ color: "#FFF" }}>Credit</Typography>
            </Box>

            <div style={{ width: "100%", height: "5px", backgroundColor: "darkgrey", borderRadius: "5px" }}>
                <div
                    style={{
                        width: `${(credit / 60) * 100}%`,
                        height: "5px",
                        backgroundColor: "#a10cb7",
                        borderRadius: "5px"
                    }}
                ></div>
            </div>


            <Typography variant="body2" sx={{ color: "#FFF" }}>Remaining Credits : {credit}</Typography>

        </Box>
    )
}

export default Credit;