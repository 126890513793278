const generateSessionID = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
};

const sessionStart = () => {
    let sessionID = sessionStorage.getItem("SessionID");
    
    if (!sessionID) {
        sessionID = generateSessionID();
        sessionStorage.setItem("SessionID", sessionID);
        localStorage.setItem("SessionID", sessionID);
        localStorage.setItem("IsSession", true);
    }
    
    return sessionID;
};


export { sessionStart };
