// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
* {
  font-family: "Causten", sans-serif !important;
  /* Use !important if necessary */
}

.appBar,
.toolBar {
  background-color: #fff !important;
}

.toolBar {
  display: flex;
  justify-content: "space-between";
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,6CAA6C;EAC7C,gCAAgC;AAClC;;AAEA;;EAEE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,gCAAgC;AAClC","sourcesContent":["\n* {\n  font-family: \"Causten\", sans-serif !important;\n  /* Use !important if necessary */\n}\n\n.appBar,\n.toolBar {\n  background-color: #fff !important;\n}\n\n.toolBar {\n  display: flex;\n  justify-content: \"space-between\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
