import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // If the response is successful and does not contain `{ auth: false }`
    return response;
  },
  (error) => {
    // Check if the response data contains `{ auth: false }`
    if (error.response && error.response.data && error.response.data.auth === false) {
      // Clear localStorage and sessionStorage
      localStorage.clear();
      sessionStorage.clear();

      // Optionally, redirect the user to the login page
      window.location.href = '/login'; // replace with your login path
      window.location.reload();
    }
    // Reject the promise so the error can be caught in the calling function
    return Promise.reject(error);
  }
);

export default api;
