import { Route, Routes } from "react-router-dom";
import AdminOverview from "./pages/admin/Overview.admin";
import AdminAssistant from "./pages/admin/Assistant.admin";
import AdminPhoneNumber from "./pages/admin/PhoneNumber.admin";
import AdminLogs from "./pages/admin/Logs.admin";
import Profile from "./pages/Profile";
import Member from "./pages/admin/Member.admin";
import AssistantDetailAdmin from "./pages/admin/AssistantDetail.admin";
import PhoneNumberDetailAdmin from "./pages/admin/PhoneNumberDetail.admin";

export const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="*" element={<AdminOverview />} />
            <Route path="/company/:company_id/overview" element={<AdminOverview />} />
            <Route path="/company/:company_id/assistants" element={<AdminAssistant />} />
            <Route path="/company/:company_id/phone-number" element={<AdminPhoneNumber />} />
            <Route path="/company/:company_id/logs" element={<AdminLogs />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/company/:company_id/member" element={<Member />} />
            <Route path="/company/:company_id/assistant/:id" element={<AssistantDetailAdmin />} />
            <Route path="/company/:company_id/phone-number/:id" element={<PhoneNumberDetailAdmin />} />
        </Routes>
    );
};
