export function TimeStamp(createdAt, endedAt) {
    const created = new Date(createdAt);
    const ended = new Date(endedAt);

    const date = created.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });

    const formatTime = (date) => {
        return date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
        });
    };

    const startTime = formatTime(created);
    const endTime = formatTime(ended);

    return `${date} | ${startTime ? startTime : "NA"} - ${endTime !=="Invalid Date" ? endTime  : "NA"}`
}