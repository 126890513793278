import React, { useCallback, useEffect, useState } from 'react'
import Navbar from "../../components/Navbar"
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Pagination, Select, TextField, Typography } from '@mui/material';
import CallCard from "../../components/CallCard ";
import api from "../../api";
import { useParams } from 'react-router-dom';
import NoDataFound from '../../components/NoDataFound';
import Loader from '../../components/Loader';
import { Add, Close } from '@mui/icons-material';
import { toast } from 'sonner';


const PhoneNumber = () => {
    const [PhoneNumberList, setPhoneNumberList] = useState([]);
    const token = JSON.parse(localStorage.getItem("token")).token;
    const OrganizationId = localStorage.getItem("Organization");
    const { company_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [phoneProviders, setPhoneProviders] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [formValues, setFormValues] = useState({
        PhoneNumber: '',
        ApiCredentials: '',
        Provider: '',
        Label: ''
    });

    const getAllPhoneNumbers = async () => {
        setLoading(true);
        try {
            const response = await api.post(`/organizations/phones-by-company`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            setPhoneNumberList(response.data.data);
            setTotalPages(response.data.totalPages);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const getAllPhoneProviders = useCallback(async () => {
        try {
            const response = await api.get(`/providers`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            setPhoneProviders(response.data?.data?.providers);
        } catch (error) {
            console.log(error);
        }
    }, [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formValues.OrganizationId) {
            formValues.Organization = OrganizationId;
        }
        try {
            await api.post(`/phones/create-phone`, formValues, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success("Phone created successfully");
            setOpen(false);
            getAllPhoneNumbers();
        } catch (error) {
            toast.error('Error creating phone');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    useEffect(() => {
        if (token) {
            getAllPhoneNumbers();
            getAllPhoneProviders();
        }
    }, [token]);

    if (loading) {
        return <Loader />
    }

    if (!PhoneNumberList.length) {
        return (
            <>
                <Navbar title="Phone Number" />
                <Box sx={{ display: 'flex', justifyContent: 'center', width: "100%", height: "90dvh" }}>
                    <NoDataFound
                        title={"Phone Numbers"}
                        subtitle="No phone numbers Assigned to this company, Try to connect with the LiaPlus Team to add phone numbers."
                    />
                </Box>
            </>
        )
    }

    return (
        <>
            < Navbar title="Phone Numbers" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
                <Box sx={{ alignSelf: "flex-end" }}>
                    <Button sx={{ bgcolor: "#02042D", color: "#FFF" }} variant="contained" onClick={() => setOpen(true)}><Add /> Add Phone Number</Button>
                </Box>

                <Grid container spacing={2}>
                    {PhoneNumberList.length > 0 && (
                        PhoneNumberList.map((number) => (
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <CallCard
                                    key={number.id}
                                    data={number}
                                    url={`/company/${company_id}/phone-number/${number._id}`}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>

                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(e, page) => {
                        setCurrentPage(page);
                    }}
                    color="#02042D"
                    style={{ alignSelf: "center" }}
                />

            </Box>


            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>

                    <Box sx={{ width: "30rem", display: "flex", flexDirection: "column", gap: "2rem", bgcolor: "#FFF", padding: "2rem", borderRadius: "8px" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Typography variant="h6" color="text.secondary" sx={{ color: "#020429" }}>
                                    Add Phone Number
                                </Typography>

                            </Box>

                            <Close onClick={() => setOpen(false)} sx={{ cursor: "pointer" }} />

                        </Box>

                        <form onSubmit={handleSubmit} style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <FormControl>
                                <InputLabel>Provider</InputLabel>
                                <Select
                                    label="Phone Provider"
                                    name="Provider"
                                    value={formValues.Provider}
                                    onChange={handleInputChange}
                                    fullWidth
                                    size="small"
                                >
                                    {phoneProviders?.map((provider, index) => (
                                        <MenuItem key={index} value={provider._id}>
                                            {provider.ProviderName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Label"
                                name="Label"
                                value={formValues.Label}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                size="small"
                            />
                            <TextField
                                label="Phone Number"
                                name="PhoneNumber"
                                value={formValues.PhoneNumber}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                size="small"
                            />
                            <TextField
                                label="API Credentials"
                                name="ApiCredentials"
                                value={formValues.ApiCredentials}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                size="small"
                                multiline
                                rows={5}
                            />

                            <Button type="submit" variant="contained" sx={{ bgcolor: "#02042D" }}>
                                Submit
                            </Button>
                        </form>

                    </Box>

                </Box>
            </Modal>
        </>
    )
}

export default PhoneNumber;