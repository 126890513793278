export const COLORS = ["#00C49F", "#FFBB27", "#0088FE", "#FF8042"]



export const countries = [
    { name: 'Afghanistan', code: '+93', flag: '🇦🇫', regex: /^(\+93|0)?[2-7]\d{8}$/ },
    { name: 'Albania', code: '+355', flag: '🇦🇱', regex: /^(\+355|0)?[6-7]\d{8}$/ },
    { name: 'Algeria', code: '+213', flag: '🇩🇿', regex: /^(\+213|0)?[5-9]\d{8}$/ },
    { name: 'American Samoa', code: '+1 684', flag: '🇦🇸', regex: /^(\+1|0)?684\d{7}$/ },
    { name: 'Andorra', code: '+376', flag: '🇦🇩', regex: /^(\+376|0)?[346]\d{5}$/ },
    { name: 'Angola', code: '+244', flag: '🇦🇴', regex: /^(\+244|0)?[9]\d{8}$/ },
    { name: 'Anguilla', code: '+1 264', flag: '🇦🇮', regex: /^(\+1|0)?264\d{7}$/ },
    { name: 'Antarctica', code: '+672', flag: '🇦🇶', regex: /^(\+672|0)?\d{6}$/ },
    { name: 'Antigua and Barbuda', code: '+1 268', flag: '🇦🇬', regex: /^(\+1|0)?268\d{7}$/ },
    { name: 'Argentina', code: '+54', flag: '🇦🇷', regex: /^(\+54|0)?[9]\d{10}$/ },
    { name: 'Armenia', code: '+374', flag: '🇦🇲', regex: /^(\+374|0)?[4-9]\d{7}$/ },
    { name: 'Aruba', code: '+297', flag: '🇦🇼', regex: /^(\+297|0)?[5-8]\d{6}$/ },
    { name: 'Australia', code: '+61', flag: '🇦🇺', regex: /^(\+61|0)?[2-478]\d{8}$/ },
    { name: 'Austria', code: '+43', flag: '🇦🇹', regex: /^(\+43|0)?[1-9]\d{9}$/ },
    { name: 'Azerbaijan', code: '+994', flag: '🇦🇿', regex: /^(\+994|0)?[4-9]\d{8}$/ },
    { name: 'Bahamas', code: '+1 242', flag: '🇧🇸', regex: /^(\+1|0)?242\d{7}$/ },
    { name: 'Bahrain', code: '+973', flag: '🇧🇭', regex: /^(\+973|0)?[3569]\d{7}$/ },
    { name: 'Bangladesh', code: '+880', flag: '🇧🇩', regex: /^(\+880|0)?1[3-9]\d{8}$/ },
    { name: 'Barbados', code: '+1 246', flag: '🇧🇧', regex: /^(\+1|0)?246\d{7}$/ },
    { name: 'Belarus', code: '+375', flag: '🇧🇾', regex: /^(\+375|0)?[1-9]\d{8}$/ },
    { name: 'Belgium', code: '+32', flag: '🇧🇪', regex: /^(\+32|0)?[1-9]\d{8}$/ },
    { name: 'Belize', code: '+501', flag: '🇧🇿', regex: /^(\+501|0)?[2-8]\d{6}$/ },
    { name: 'Benin', code: '+229', flag: '🇧🇯', regex: /^(\+229|0)?[4-9]\d{7}$/ },
    { name: 'Bermuda', code: '+1 441', flag: '🇧🇲', regex: /^(\+1|0)?441\d{7}$/ },
    { name: 'Bhutan', code: '+975', flag: '🇧🇹', regex: /^(\+975|0)?[1-9]\d{6,7}$/ },
    { name: 'Bolivia', code: '+591', flag: '🇧🇴', regex: /^(\+591|0)?[67]\d{7}$/ },
    { name: 'Bosnia and Herzegovina', code: '+387', flag: '🇧🇦', regex: /^(\+387|0)?[3-9]\d{7,8}$/ },
    { name: 'Botswana', code: '+267', flag: '🇧🇼', regex: /^(\+267|0)?[1-8]\d{6}$/ },
    { name: 'Brazil', code: '+55', flag: '🇧🇷', regex: /^(\+55|0)?[1-9]{2}9?\d{8}$/ },
    { name: 'British Indian Ocean Territory', code: '+246', flag: '🇮🇴', regex: /^(\+246|0)?\d{7}$/ },
    { name: 'Brunei', code: '+673', flag: '🇧🇳', regex: /^(\+673|0)?[2-8]\d{6}$/ },
    { name: 'Bulgaria', code: '+359', flag: '🇧🇬', regex: /^(\+359|0)?[1-9]\d{8}$/ },
    { name: 'Burkina Faso', code: '+226', flag: '🇧🇫', regex: /^(\+226|0)?[5-8]\d{7}$/ },
    { name: 'Burundi', code: '+257', flag: '🇧🇮', regex: /^(\+257|0)?[1-9]\d{7}$/ },
    { name: 'Cambodia', code: '+855', flag: '🇰🇭', regex: /^(\+855|0)?[1-9]\d{7,8}$/ },
    { name: 'Cameroon', code: '+237', flag: '🇨🇲', regex: /^(\+237|0)?[1-9]\d{8}$/ },
    { name: 'Canada', code: '+1', flag: '🇨🇦', regex: /^(\+1|0)?[2-9]\d{9}$/ },
    { name: 'Cape Verde', code: '+238', flag: '🇨🇻', regex: /^(\+238|0)?[1-9]\d{6}$/ },
    { name: 'Cayman Islands', code: '+1 345', flag: '🇰🇾', regex: /^(\+1|0)?345\d{7}$/ },
    { name: 'Central African Republic', code: '+236', flag: '🇨🇫', regex: /^(\+236|0)?[1-9]\d{7}$/ },
    { name: 'Chad', code: '+235', flag: '🇹🇩', regex: /^(\+235|0)?[1-9]\d{7}$/ },
    { name: 'Chile', code: '+56', flag: '🇨🇱', regex: /^(\+56|0)?9\d{8}$/ },
    { name: 'China', code: '+86', flag: '🇨🇳', regex: /^(\+86|0)?1[3-9]\d{9}$/ },
    { name: 'Christmas Island', code: '+61', flag: '🇨🇽', regex: /^(\+61|0)?[2-478]\d{8}$/ },
    { name: 'Cocos (Keeling) Islands', code: '+61', flag: '🇨🇨', regex: /^(\+61|0)?[2-478]\d{8}$/ },
    { name: 'Colombia', code: '+57', flag: '🇨🇴', regex: /^(\+57|0)?3\d{9}$/ },
    { name: 'Comoros', code: '+269', flag: '🇰🇲', regex: /^(\+269|0)?[3-9]\d{6}$/ },
    { name: 'Congo', code: '+242', flag: '🇨🇬', regex: /^(\+242|0)?[1-9]\d{7}$/ },
    { name: 'Cook Islands', code: '+682', flag: '🇨🇰', regex: /^(\+682|0)?\d{5}$/ },
    { name: 'Costa Rica', code: '+506', flag: '🇨🇷', regex: /^(\+506|0)?[2-8]\d{7}$/ },
    { name: 'Croatia', code: '+385', flag: '🇭🇷', regex: /^(\+385|0)?[1-9]\d{8}$/ },
    { name: 'Cuba', code: '+53', flag: '🇨🇺', regex: /^(\+53|0)?[1-9]\d{7}$/ },
    { name: 'Cyprus', code: '+357', flag: '🇨🇾', regex: /^(\+357|0)?[2469]\d{7}$/ },
    { name: 'Czech Republic', code: '+420', flag: '🇨🇿', regex: /^(\+420|0)?[1-9]\d{8}$/ },
    { name: 'Democratic Republic of the Congo', code: '+243', flag: '🇨🇩', regex: /^(\+243|0)?[1-9]\d{8}$/ },
    { name: 'Denmark', code: '+45', flag: '🇩🇰', regex: /^(\+45|0)?[2-9]\d{7}$/ },
    { name: 'Djibouti', code: '+253', flag: '🇩🇯', regex: /^(\+253|0)?[1-9]\d{6}$/ },
    { name: 'Dominica', code: '+1 767', flag: '🇩🇲', regex: /^(\+1|0)?767\d{7}$/ },
    { name: 'Dominican Republic', code: '+1 809', flag: '🇩🇴', regex: /^(\+1|0)?8[024]9\d{7}$/ },
    { name: 'Ecuador', code: '+593', flag: '🇪🇨', regex: /^(\+593|0)?[2-9]\d{7,8}$/ },
    { name: 'Egypt', code: '+20', flag: '🇪🇬', regex: /^(\+20|0)?1[0-2]\d{8}$/ },
    { name: 'El Salvador', code: '+503', flag: '🇸🇻', regex: /^(\+503|0)?[267]\d{7}$/ },
    { name: 'Equatorial Guinea', code: '+240', flag: '🇬🇶', regex: /^(\+240|0)?[1-9]\d{8}$/ },
    { name: 'Eritrea', code: '+291', flag: '🇪🇷', regex: /^(\+291|0)?[1-9]\d{6}$/ },
    { name: 'Estonia', code: '+372', flag: '🇪🇪', regex: /^(\+372|0)?[3-9]\d{6,7}$/ },
    { name: 'Ethiopia', code: '+251', flag: '🇪🇹', regex: /^(\+251|0)?[1-9]\d{8}$/ },
    { name: 'Falkland Islands', code: '+500', flag: '🇫🇰', regex: /^(\+500|0)?\d{5}$/ },
    { name: 'Faroe Islands', code: '+298', flag: '🇫🇴', regex: /^(\+298|0)?\d{6}$/ },
    { name: 'Fiji', code: '+679', flag: '🇫🇯', regex: /^(\+679|0)?[2-9]\d{6}$/ },
    { name: 'Finland', code: '+358', flag: '🇫🇮', regex: /^(\+358|0)?[1-9]\d{8}$/ },
    { name: 'France', code: '+33', flag: '🇫🇷', regex: /^(\+33|0)?[1-9]\d{8}$/ },
    { name: 'French Polynesia', code: '+689', flag: '🇵🇫', regex: /^(\+689|0)?[2-9]\d{5}$/ },
    { name: 'Gabon', code: '+241', flag: '🇬🇦', regex: /^(\+241|0)?[1-9]\d{6}$/ },
    { name: 'Gambia', code: '+220', flag: '🇬🇲', regex: /^(\+220|0)?[2-9]\d{6}$/ },
    { name: 'Georgia', code: '+995', flag: '🇬🇪', regex: /^(\+995|0)?[1-9]\d{8}$/ },
    { name: 'Germany', code: '+49', flag: '🇩🇪', regex: /^(\+49|0)?[1-9]\d{10}$/ },
    { name: 'Ghana', code: '+233', flag: '🇬🇭', regex: /^(\+233|0)?[2-9]\d{8}$/ },
    { name: 'Gibraltar', code: '+350', flag: '🇬🇮', regex: /^(\+350|0)?[5-8]\d{7}$/ },
    { name: 'Greece', code: '+30', flag: '🇬🇷', regex: /^(\+30|0)?[2-7]\d{9}$/ },
    { name: 'Greenland', code: '+299', flag: '🇬🇱', regex: /^(\+299|0)?\d{6}$/ },
    { name: 'Grenada', code: '+1 473', flag: '🇬🇩', regex: /^(\+1|0)?473\d{7}$/ },
    { name: 'Guam', code: '+1 671', flag: '🇬🇺', regex: /^(\+1|0)?671\d{7}$/ },
    { name: 'Guatemala', code: '+502', flag: '🇬🇹', regex: /^(\+502|0)?[2-7]\d{7}$/ },
    { name: 'Guinea', code: '+224', flag: '🇬🇳', regex: /^(\+224|0)?[6-7]\d{8}$/ },
    { name: 'Guinea-Bissau', code: '+245', flag: '🇬🇼', regex: /^(\+245|0)?[5-9]\d{7}$/ },
]

export const radar = [
    {
        subject: 'Math',
        A: 120,
        B: 110,
        fullMark: 150,
    },
    {
        subject: 'Chinese',
        A: 98,
        B: 130,
        fullMark: 150,
    },
    {
        subject: 'English',
        A: 86,
        B: 130,
        fullMark: 150,
    },
    {
        subject: 'Geography',
        A: 99,
        B: 100,
        fullMark: 150,
    },
    {
        subject: 'Physics',
        A: 85,
        B: 90,
        fullMark: 150,
    },
    {
        subject: 'History',
        A: 65,
        B: 85,
        fullMark: 150,
    },
];